<template>
  <div class="reserve-form-wrap">
    <!-- 예약입력  -->
    <div class="reserve-form-wrap--info">
      <div class="reserve-form-wrap--top">
        <p class="reserve-form-wrap--title"><strong>휴대전화 번호</strong>를<br>입력해주세요.</p>
        <p class="reserve-form-wrap--text">
          방문예약하신 고객님은<br/>
          <strong>예약하신 휴대전화번호로 방문등록</strong>을 하고<br/>
          안내에 따라 입장하여 주시기 바랍니다.
        </p>
      </div>
      <div class="reserve-form-wrap--sub">
        <div class="reserve-form-wrap--sub-kakao">
          방문등록하고 <strong>카카오톡</strong>으로 확인하세요
        </div>
      </div>

    </div>

    <!-- 약관 동의 -->
    <div class="reserve-form-wrap--terms">
      <div class="reserve-form-wrap--terms-survey">
        <ul>
          <li v-for="(item,index) in SURVEY_LIST" :key="item.index">
            <p class="reserve-form-wrap--terms-survey--q" :class="{ required: item.required }">
              {{ item.Q }}
            </p>
            <div class="reserve-form-wrap--terms-survey--a">
              <v-radio-group v-model="radioGroupSelected[item.index]">
                <v-radio
                  v-for="(child) in item.A"
                  :key="child.text"
                  :label="child.text"
                  :on-icon="'svg-rad-on'"
                  :off-icon="'svg-rad-off'"
                  :value="child.text">
              </v-radio>
              </v-radio-group>
            </div>
          </li>
        </ul>

      </div>

      <div class="reserve-form-wrap--terms-btn">
        <button type="button" class="btn-keypad-del"><span class="hide">del</span></button>
        <button type="button" class="btn-keypad-in">입력</button>
      </div>

    </div>
  </div>
  </template>

  <script>
    export default {
      name: 'CAP0570',
      components: {},
      props: {
      },
      data() {
        return {
          TERMS_TITLE: '더샵 거창포르시엘2차 방문등록',
          radioGroupSelected: '',
          SURVEY_LIST: [
          {
            Q: "귀하의 연령대를 선택하세요.",
            A: [
              { text: '20대'},
              { text: '30대'},
              { text: '40대'},
              { text: '50대'},
              { text: '60대 이상'},
            ],
            required: true,
          },
          {
            Q: "현재 거주 지역을 선택하세요.",
            A: [
              { text: '광양시'},
              { text: '순천시'},
              { text: '여수시'},
            ],
            required: true,
          },
        ],

        }
      },
      mounted() {},
      computed: {},
      methods: {

      }
    }
  </script>

  <style>
  </style>